/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: "Baloo";
  src: url("./assets/fonts/baloo/Baloo-Regular.ttf");
}

@font-face {
  font-family: "Monstro";
  src: url("./assets/fonts/monstro_solid/MonstroSolid.otf");
}


@font-face {
  font-family: "Futura";
  src: url(./assets/fonts/futura/Futura.ttc);
}


@font-face {
  font-family: "FuturaBold";
  src: url(./assets/fonts/futura/FuturaBold.otf);
}



:root {
  --bs-primary-rgb: 255, 208, 0;
  --bs-secondary-rgb: 43, 41, 38;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-info-rgb: 222, 118, 28;
  --bs-warning-rgb: 255, 242, 0;
  --bs-success-rgb: 15, 179, 0;
  --bs-danger-rgb: 230, 0, 42;
  --bs-link-color-rgb: 29, 136, 237;
  --bs-dark-rgb: 0, 0, 0;
  --bento-brown: #de761c;
  --bs-body-color: #2b2926;
  --bs-border-color: rgba(98, 97, 110, 0.3);
  --bs-border-color-translucent: var(--bs-border-color);
  --bs-border-radius: 10px;
  --bs-body-font-family: "Futura";
  --baloo: "Baloo";
  --monstro: "Monstro";
  --futura-bold:"FuturaBold";
  --futura: var(--bs-body-font-family);
  --mat-expansion-container-text-font: var(--bs-body-font-family);
  --mat-expansion-header-text-font: var(--bs-body-font-family);
  --mat-expansion-container-text-size: var(--bs-body-font-size);
  --bs-body-font-size: 16px;
  --bs-body-line-height: normal;
  --h1: 64px;
  --h2: 48px;
  --h3: 28px;
  --h4: 26px;
  --h5: 20px;
  --h6: 16px;
  --fs-1: 76px;
  --fs-2: 42px;
  --fs-3: 24px;
  --fs-4: 22px;
  --fs-5: 18px;
  --fs-6: 14px;

  @media screen and (max-width: 991px) {
    --bs-body-font-size: 12px;
    --h1: 48px;
    --h2: 28px;
    --h3: 22px;
    --h5: 18px;
    --fs-5: 14px;
  }

  @media screen and (max-width: 767px) {
    --h1: 28px;
    --h2: 20px;
    --h5: 16px;
  }

  @media screen and (max-width: 575px) {
    --h3: 20px;
    --fs-4: 18px;
    --h5: 14px;
    --h6: 12px;
  }
}

* {
  outline-width: 0px;
  line-height: normal;
  user-select: none;
}

h1 {
  font-size: var(--h1);
}

h2 {
  font-size: var(--h2);
}

h3 {
  font-size: var(--h3);
}

h4 {
  font-size: var(--h4);
}

h5 {
  font-size: var(--h5);
}

h6 {
  font-size: var(--h6);
}

.fs-1 {
  font-size: var(--fs-1) !important;
}

.fs-2 {
  font-size: var(--fs-2) !important;
}

.fs-3 {
  font-size: var(--fs-3) !important;
}

.fs-4 {
  font-size: var(--fs-4) !important;
}

.fs-5 {
  font-size: var(--fs-5) !important;
}

.fs-6 {
  font-size: var(--fs-6) !important;
}

.btn {
  --bs-bg-opacity: 1;
  --bs-btn-font-size: var(--bs-body-font-size);
  --bs-btn-focus-box-shadow: none;
}

.btn-close {
  --btn-close-ratio: 30px;
  background: center/var(--btn-close-ratio) no-repeat
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='none'%3E%3Cpath d='M26.25 26.25L3.75 3.75' stroke='%232B2926' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M26.25 3.75001L3.75 26.25' stroke='%232B2926' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  width: var(--btn-close-ratio);
  height: var(--btn-close-ratio);
}

a {
  text-decoration: none;
}

.btn-primary {
  --bs-btn-bg: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
  --bs-btn-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity));
  --bs-btn-border-width: 0;
  --bs-btn-hover-bg: #edc201;
  --bs-btn-active-color: var(--bs-btn-color);
  --bs-btn-hover-color: var(--bs-btn-color);
  --bs-btn-active-bg: #cda804;
  --bs-btn-disabled-bg: rgba(var(--bs-primary-rgb), 0.8);
}

.offer-applied {
  top: -20px;
  left: -19px;

  img {
    width: 32px;
  }
}

.dish-img {
  width: 140px;
  height: 140px;
  object-fit: contain;
}

.btn-secondary {
  --bs-btn-bg: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity));
}

.btn-info {
  --bs-btn-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-btn-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-btn-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-btn-bg: rgba(var(--bs-info-rgb), 1);
  --bs-btn-border-width: 0;
  --bs-btn-hover-bg: #cc6d19;
  --bs-btn-active-bg: #b25e14;
}

.btn-warning {
  --bs-btn-color: rgba(var(--bs-secondary-rgb), 1);
  --bs-btn-hover-color: rgba(var(--bs-secondary-rgb), 1);
  --bs-btn-active-color: rgba(var(--bs-secondary-rgb), 1);
  --bs-btn-bg: rgba(var(--bs-warning-rgb), 1);
  --bs-btn-border-width: 0;
  --bs-btn-hover-bg: rgb(230, 219, 12);
  --bs-btn-active-bg: rgb(188, 179, 8);
}

.btn-success {
  --bs-bg-opacity: 0.15;
  --bs-btn-bg: rgba(var(--bs-success-rgb), var(--bs-bg-opacity));
  --bs-btn-border-width: 0;
  --bs-btn-color: rgba(var(--bs-success-rgb), 1);
}

.btn-danger {
  --bs-btn-bg: #ff5722;
}

.btn-shadow {
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.26);
}

.btn-close {
  --bs-btn-close-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
}

.font-secondary {
  font-family: var(--monstro);
  text-transform: uppercase;

  * {
    text-transform: uppercase;
  }
}

.form-check {
  --radio-size: 24px;
  padding-left: calc(var(--radio-size) + 6px);

  .form-check-input {
    padding: calc((var(--radio-size) / 2) - 1px);
    margin-top: 0;
    margin-left: calc((var(--radio-size) + 6px) * -1);
  }

    }
    .form-check-label {
        line-height: var(--radio-size);
    }

.form-check-input:checked {
  background-color: rgba(var(--bs-secondary-rgb), 1);
  border-color: rgba(var(--bs-secondary-rgb), 1);
}

.radio {
  .form-check-input {
    &:checked {
      background-color: rgba(var(--bs-emphasis-color-rgb), 1);
    }

    &:checked[type="radio"] {
      --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%232b2926'/%3e%3c/svg%3e");
    }
  }
}

.form-control {
  &.theme-padding {
    padding: 12px 16px;
  }

  &:focus {
    border-color: black;
  }
}

.dropdown-menu {
  --bs-dropdown-item-padding-y: 12px;
}

.cart-count {
  width: fit-content;
  --badge-h: 24px;
  height: var(--badge-h);
  min-width: var(--badge-h);
  line-height: var(--badge-h);
  background: rgba(var(--bs-danger-rgb), 1);
  color: var(--bs-navbar-color);
  right: calc(var(--badge-h) / 2 * -1);
}

.container.container-2 {
  @media screen and (min-width: 1400px) {
    max-width: 83.125%;
  }
}

.container {
  @media screen and (max-width: 991px) {
    max-width: 100%;
  }
}

.router-outlet {
  min-height: calc(100vh - 178px);
}

.navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'%3E%3Cpath d='M1 1H21' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1 11L21 11' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1 21H21' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
  width: 20px;
  height: 20px;
}

.navbar {
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-padding-y: 3.2px;

  .navbar-brand {
    img {
      max-width: 350px;

      @media screen and (max-width: 1440px) {
        max-width: 250px;
      }
    }
  }

  .nav-item {
    --nav-x-margin: 22px;
    margin: 0 var(--nav-x-margin);

    @media screen and (max-width: 1440px) {
      --nav-x-margin: 8px;

      &.item-2 {
        margin: 0 5px;
      }
    }

    &.item-2 {
      margin: 0 10px;

      &:first-child {
        // margin-left: var(--nav-x-margin);
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .nav-link {
      position: relative;
      --bs-nav-link-font-size: var(--h5);

      &.active {
        color: rgba(var(--bs-warning-rgb), 1);
        &::after {
          position: absolute;
          content: "";
          width: 12px;
          height: 4px;
          border-radius: 40px;
          background: rgba(var(--bs-primary-rgb), 1);
          bottom: -12px;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    .navbar-brand {
      order: 2;

      img {
        max-width: 330px;
      }
    }

    .navbar-toggler {
      order: 1;
    }

    .nav-2 {
      order: 3;

      .item-2 {
        &:first-child {
          // order: 2;
        }

        &:nth-child(2) {
          // order: 1;
        }

        &:last-child {
          display: none;
        }
      }
    }

    .navbar-collapse {
      position: fixed;
      top: 0;
      bottom: 0;
      height: 100% !important;
      width: 100%;
      left: 0;
      right: 0;
      transition: transform 0.5s ease;
      z-index: 1;
      transform: translateX(-100%);

      &.show {
        transform: translateX(0%);
      }

      .navbar-nav {
        position: relative;
        background: rgba(var(--bs-dark-rgb), 1);
        width: 100%;
        height: 100%;
        max-width: 340px;
        padding: 92px 36px;
        align-items: start !important;
        overflow: auto;

        .btn-close {
          left: 270px;
          top: 10px;
        }

        .nav-logout {
          &::after {
            position: absolute;
            content: "";
            width: 100vw;
            max-width: 340px;
            border: 1px solid;
            top: 0;
            left: -36px;
          }
        }

        .nav-item {
          margin: 0 0 34px;

          .nav-link {
            &::before {
              content: "";
              width: 24px;
              display: inline-block;
              height: 24px;
              vertical-align: top;
              margin-right: 24px;
              background: var(--nav-icon-phone) center/contain no-repeat;
            }

            &::after {
              content: none;
            }

            &.active {
              color: rgba(var(--bs-primary-rgb), 1);

              &::before {
                filter: invert(66%) sepia(70%) saturate(592%) hue-rotate(3deg)
                  brightness(208%) contrast(104%);
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .navbar-brand {
      margin-inline: 10px auto;

      img {
        max-width: 156px;
      }
    }

    .navbar-toggler {
      padding-inline: 0;
    }

    .nav-2 {
      .item-2 {
        margin: 0;

        img {
          width: 24px;
          height: 24px;
        }

        &:first-child {
          // margin-right: 12px;
        }
      }
    }
  }
}

.btn-outline-dark {
  --bs-btn-border-color: rgba(98, 97, 110, 0.3);

  &:hover {
    .white-icon {
      filter: invert(1);
    }
  }
}

.card-shadow {
  box-shadow: 0px 4px 4px 0px rgba(var(--bs-dark-rgb), 0.05);
}

.banner-wrapper {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.3) 100%
    );
  }

  #bannerSlider {
    [class*="carousel-control"] {
      pointer-events: none;
      z-index: 2;

      span {
        pointer-events: all;
        padding: 11px;
        cursor: pointer;

        @media screen and (max-width: 992px) {
          padding: 7px;

          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .search-div {
    z-index: 1;
    width: 100%;
    max-width: 856px;

    @media screen and (max-width: 992px) {
      max-width: calc(100% - 120px);
    }

    @media screen and (max-width: 767px) {
      max-width: calc(100% - 20px);
    }

    h1 {
      color: rgba(var(--bs-emphasis-color-rgb), 1);
    }

    .search-wrapper {
      padding-block: 10px;
      margin-bottom: 30px;
      box-shadow: 0px 18px 14px 0px rgba(var(--bs-dark-rgb), 0.27);
      --bs-border-color: rgba(59, 58, 74, 0.15);

      input {
        padding-left: 92px;
      }

      @media screen and (max-width: 992px) {
        margin-bottom: 16px;
        padding-block: 0;

        img {
          width: 37px;
        }

        input {
          padding-left: 62px;
        }
      }
    }

    .btn-location {
      box-shadow: 0px 2px 2px 0px rgba(var(--bs-dark-rgb), 0.27);
    }

    .responsive-search-wrapper {
      @media screen and (max-width: 767px) {
        // display: flex;
        margin-bottom: 8px;

        .btn-location {
          order: 2;
          padding-inline: 8px;
          margin-left: 6px;
          width: 50%;

          img {
            width: 14px;
            height: 14px;

            &:last-child {
              display: none;
            }
          }

          span {
            display: none;
          }

          &::after {
            content: "Current Location";
            vertical-align: middle;
            margin-left: 4px;
          }
        }

        .search-wrapper {
          order: 1;
          margin-bottom: 0;
          width: 100%;
          margin-top: 8px;

          img {
            width: 14px;
            height: 14px;
          }

          input {
            padding-left: 29px;
            padding-block: 4.5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.note-strip {
  padding-block: 18px;

  @media screen and (max-width: 991px) {
    padding-block: 10px;
  }
}

.back-strip {
  top: 67px;
  border-bottom: 1px solid var(--bs-border-color);
  z-index: 1;
  height: 70px!important;
  

  @media screen and (max-width: 991px) {
    top: 62px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    border-bottom: none;
  }

  @media screen and (max-width: 767px) {
    top: 47px;
    height: 55px!important;
  }
}

.special-offers {
  margin-block: 60px 83px;

  h2 {
    margin-bottom: 10px;
  }

  h6 {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 991px) {
    margin-block: 36px 56px;

    h2 {
      margin-bottom: 14px;
    }

    h6 {
      margin-bottom: 24px;
      --h6: 12px;
    }

    .card {
      img {
        max-width: 84px;
        height: 84px;
        margin-bottom: 4px !important;
      }
    }
  }

  @media screen and (max-width: 575px) {
    .card {
      flex-direction: row;

      img {
        margin-right: 18px;
      }

      .card-body-sm {
        flex: 1 0 0%;
        max-width: calc(100vw - 160px);

        h3 {
          --h3: 20px;
        }
      }
    }
  }
}

.working-hr-list [class*="col"] {
  --bs-border-radius: 4px;
  --bs-gutter-x: 18px;

  &:nth-child(even) {
    .card {
      background: rgba(var(--bs-secondary-rgb), 0.08);
    }
  }

  .weekly-timing {
    width: calc(100% - 120px);
  }

  @media screen and (max-width: 767px) {
    .weekly-timing {
      width: calc(100% - 82px);
    }
  }

  @media screen and (max-width: 575px) {
    .weekly-timing {
      width: calc(100% - 88px);
    }
  }

  @media screen and (min-width: 575px) and (max-width: 991px) {
    &:nth-child(even) {
      .card {
        background: rgba(var(--bs-white-rgb), 0.08);
      }
    }
  }
}

.dropdown-menu {
  --bs-dropdown-padding-y: 20px;
  --bs-dropdown-item-padding-x: 24px;
  --bs-dropdown-link-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-dropdown-font-size: var(--fs-5);
  --bs-dropdown-bg: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity));

  &.menu-2 {
    --bs-dropdown-padding-y: 18px;
    --bs-dropdown-item-padding-y: 8px;
    --bs-dropdown-link-color: rgba(var(--bs-secondary-rgb), 1);
    --bs-dropdown-bg: rgba(var(--bs-white-rgb), 1);
    --bs-dropdown-min-width: 76px;
    border: none;
    box-shadow: 0px 3px 12px 3px rgba(0, 0, 0, 0.1);

    .dropdown-item {
      cursor: pointer;

      &:hover {
        background-color: rgba(var(--bs-secondary-rgb), 0.1);
      }
    }
  }
}

.order-slides {
  .card {
    min-height: 364px;
  }
}

.orders-row {
  --bs-gutter-x: 16px;
  --bs-gutter-y: 24px;

  @media screen and (max-width: 991px) {
    --bs-gutter-y: 12px;
    --h5: 18px;
    --h6: 14px;
  }

  @media screen and (max-width: 767px) {
  }
}

.detail-row {
  --bs-gutter-x: 52px;
}

.about-row {
  --bs-gutter-x: 18px;
  --bs-gutter-y: var(--bs-gutter-x);
}

.contact-row {
  --bs-gutter-x: 72px;
  --bs-gutter-y: 24px;
}

.cart-row {
  --bs-gutter-x: 36px;
  --bs-gutter-y: 24px;
}

.cart-item-row {
  --bs-gutter-x: 50px;
  --bs-gutter-y: var(--bs-gutter-x);

  @media screen and (max-width: 575px) {
    --bs-gutter-x: 12px;
    margin-inline: -16px;
  }
}

.shop-items-row {
  --bs-gutter-x: 12px;
  --bs-gutter-y: var(--bs-gutter-x);
}

.home-row {
  --bs-gutter-x: 20px;
  --bs-gutter-y: var(--bs-gutter-x);

  @media screen and (max-width: 992px) {
    --bs-gutter-x: 16px;
  }

  @media screen and (max-width: 575px) {
    --bs-gutter-x: 8px;
  }
}

.shop-status {
  position: relative;
  --before-gap: 14px;
  margin-left: calc(var(--before-gap) * 2);
  color: var(--status-bg);

  &::after {
    position: absolute;
    content: "";
    width: var(--before-gap);
    height: var(--before-gap);
    border-radius: 50%;
    top: 0;
    bottom: 0;
    right: calc(var(--before-gap) - 4px + 100%);
    margin: auto;
    background: var(--status-bg);
  }

  &.open {
    --status-bg: rgba(var(--bs-success-rgb), 1);
  }

  &.closed {
    --status-bg: rgba(var(--bs-danger-rgb), 1);
  }

  &.openshortly {
    --status-bg: #FF8C00;
  }

  // @media screen and (max-width: 767px) {
  //   --before-gap: 8px;
  // }
}

[class*="dialog-surface"] {
  padding: 24px;
  width: 90vw !important;
  max-width: var(--dialogWidth) !important;
  --mdc-dialog-container-shape: var(--bs-border-radius);

  [mat-content] {
    max-height: var(--content-height);
  }

  @media screen and (max-width: 575px) {
    padding: 18px;

    .btn-close {
      --btn-close-ratio: 24px;
    }
  }
}

.confirm-dialog {
  --dialogWidth: 402px;
}

.faq-indicator {
  [class*="expansion-indicator"] {
    --incIcon: url(assets/icons/minus_icon.svg);

    &[style*="rotate(0deg)"] {
      --incIcon: url(assets/icons/plus_icon.svg);
    }
  }
}

[class*="expansion-indicator"] {
  --incRatio: 40px;

  &::after {
    border: none !important;
    padding: 12px;
    width: var(--incRatio);
    height: var(--incRatio);
    border-radius: 50%;
    transform: none !important;
    background: var(--incIcon) center/calc(var(--incRatio) - 44.4444%) no-repeat,
      rgba(var(--bs-secondary-rgb), 0.1);
  }
}

.faq-counter {
  counter-increment: section;

  .faq-count {
    &::before {
      content: counter(section) ".";
    }
  }
}

[class*="autocomplete-panel"] {
  [role="option"] {
    span {
      width: 100%;
    }
  }
}

.home-search {
  box-shadow: 0px 4px 24px 0px rgba(var(--bs-dark-rgb), 0.25) !important;
  max-height: 42vh !important;
  min-height: 256px;
  scrollbar-width: 5px;
}

[class*="tab-group"] {
  [class*="tab-header"] {
    --mdc-secondary-navigation-tab-container-height: auto;
    --mat-tab-header-label-text-size: var(--bs-body-font-size);
    --mat-tab-header-label-text-font: var(--bs-body-font-family);
    --mat-tab-header-label-text-tracking: auto;
    --mat-tab-header-inactive-label-text-color: var(--bs-body-color);
    --mat-tab-header-active-label-text-color: var(
      --mat-tab-header-inactive-label-text-color
    );
    --mat-tab-header-active-focus-label-text-color: var(
      --mat-tab-header-inactive-label-text-color
    );
    --mat-tab-header-active-hover-label-text-color: var(
      --mat-tab-header-inactive-label-text-color
    );
    --mat-tab-header-active-ripple-color: var(
      --mat-tab-header-inactive-label-text-color
    );
    --mat-tab-header-inactive-ripple-color: var(
      --mat-tab-header-inactive-label-text-color
    );
    margin-bottom: 18px;

    [class*="tab-header-pagination"] {
      margin-bottom: 0;
    }

    [class*="tab-label-container"] {
      [class*="tab-labels"] {
        [role="tab"] {
          overflow: hidden;
          padding: 12px 50px;
          border: 1px solid var(--bs-border-color);
          border-radius: 30px;

          &:not(:last-child) {
            margin-right: 6px;
          }

          [class*="tab-indicator"] {
            display: none;
          }

          &[class*="tab--active"] {
            &:first-child {
              background-color: rgba(var(--bs-warning-rgb), 1);
            }

            &:nth-child(2) {
              background: var(--bento-brown);
              --mat-tab-header-inactive-label-text-color: rgba(
                var(--bs-emphasis-color-rgb),
                1
              );

              [class*="text-label"] {
                color: var(--mat-tab-header-inactive-label-text-color);
              }
            }
          }
        }
      }
    }
  }

  [class*="tab-body-wrapper"] {
    [class*="tab-header"] {
      [class*="tab-label-container"] {
        [role="tab"] {
          padding: 8px 12px;
          border-radius: 4px;

          &[class*="tab--active"] {
            background: rgba(var(--bs-primary-rgb), 1);

            &:first-child,
            &:nth-child(2) {
              background: rgba(var(--bs-primary-rgb), 1);
              --mat-tab-header-inactive-label-text-color: var(--bs-body-color);
            }
          }
        }
      }
    }

    [class*="tab-body-wrapper"] {
      border-top: 1px solid var(--bs-border-color);
    }
  }
}

[class*="tab-body-content"] {
  overflow-x: hidden !important;
}

.theme-scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(var(--bs-secondary-rgb), 1);
    border: 4px solid rgba(var(--bs-emphasis-color-rgb), 1);
    border-radius: 10px;
  }
}
.scrollbar {
  &::-webkit-scrollbar {
    width: 14px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(var(--bs-secondary-rgb), 1);
    border: 4px solid rgba(var(--bs-emphasis-color-rgb), 1);
    border-radius: 10px;
    height:90px;
  }
}

.old-order {
  & ~ .shop-address {
    max-width: calc(100% - 70px);

    @media screen and (max-width: 575px) {
      max-width: calc(100% - 38px);
    }
  }
}

.reward-applied {
  top: -20px;
  left: 8px;
  background: url(assets/images/reward_bg.svg) no-repeat center/contain;
  p {
    line-height: 16px;
  }
}

.item-img {
  // width: 220px;
  height: 100%;
  height: 300px;
  object-fit: contain;
}
.reward-dish-img {
  width: 100%;
}

.custom-instruction {
  line-height: 100%;
  margin-bottom: -1.5rem;
  margin-top: 10px;
  @media screen and (max-width: 575px) {
    margin-bottom: -16px;
  }
}

.offer-instruction {
  line-height: 100%;
  margin-bottom: -0.5rem;
  margin-top: 10px;
  @media screen and (max-width: 575px) {
    margin-bottom: -16px;
  }
}

footer {
  border-top: 1px solid rgba(59, 58, 74, 0.3);
  padding-top: 12px;
}

.banner-img {
  max-height: 628px;
  height: 65vh;
  object-fit: cover;

  @media screen and (max-width: 767px) {
    max-height: 40vh;
    min-height: 40vh;
  }
}

.owl-theme .owl-dots .owl-dot.active span {
  background: var(--bs-body-color);
}

.reward-img {
  @media screen and (max-width: 1500px) {
    max-width: 150px;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  @media screen and (max-width: 991px) {
    img {
      max-width: 134px;
    }
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: var(--truncate-lines);
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.combo {
  position: relative;

  &::after {
    position: absolute;
    --combo-ratio: 95px;
    content: "";
    height: var(--combo-ratio);
    width: var(--combo-ratio);
    background: url(assets/images/combo.png) center/contain no-repeat;
    top: 0;
    right: 0;

    @media screen and (max-width: 767px) {
      --combo-ratio: 35px;
    }

    @media screen and (max-width: 575px) {
      top: auto;
      bottom: 38px;
    }
  }

  &.combo-detail {
    &::after {
      right: -20px;

      @media screen and (max-width: 991px) {
        right: auto;
        left: calc(100% + 4px);
      }

      @media screen and (max-width: 767px) {
        --combo-ratio: 95px;
      }

      @media screen and (max-width: 575px) {
        top: 0;
        bottom: auto;
        left: auto;
        right: -28px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .about-sub-heading {
    --fs-4: 14px;
  }

  .brand-img {
    img {
      width: 171px;
    }
  }

  .page-heading {
    --fs-5: 28px;
    width: calc(100% - 138px);
  }

  .item-quantity {
    border-bottom: 1px solid var(--bs-border-color);



    .dish-description {
      font-size: 16px;
    }
  }

  .pickeup-time {
    font-size: 18px;
  }

  .btn-edit {
    img {
      width: 20px;
      height: 20px;
    }
  }

  .reward-dish-img {
    width: 100%;
  }

  .form-check {
    --radio-size: 20px;
  }

  .strip-2 {
    position: sticky;
  }
}

@media screen and (max-width: 767px) {
  .shop-items-row {
    .card {
      flex-direction: column;

      .dish-img {
        width: 90px;
        height: 90px;
        object-fit: contain;
      }

      .dish-detail {
        width: calc(100% - 90px);
      }
    }
  }

  .page-heading {
    --fs-5: 20px;
    width: calc(100% - 82px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .items-list {
    .dish-img {
      width: 94px;
      height: 94px;
      object-fit: contain;
    }
  }

  .reward-dish-img {
    width: 100%;
  }
}

@media screen and (max-width: 575px) {
  .map {
    height: 269px;
  }

  .shop-items-row {
    .card {
      .dish-img {
        width: 72px;
        height: 72px;
        object-fit: contain;
      }

      .dish-detail {
        width: calc(100% - 52px);

        .custom-icon {
          width: 14px;
          height: 14px;
        }

        [class*="icon-"] {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  .reward-applied {
    top: -25px;
    left: 0px;

    img {
      width: 14px;
    }

    p {
      font-size: 8px;
    }

    --fs-6: 6px;
  }

  .items-list {
    .dish-img {
      // width: 88px;
      // height: 88px;
      width: 65px;
      height: 65px;
      object-fit: contain;
    }
  }

  .order-dish-img {
    width: 90px;
    height: 90px;
    object-fit: contain;
  }

  .home-autocomplete {
    min-width: calc(100vw - 38px);

    .history-icon {
      width: 18px;
    }
  }

  .pickeup-time {
    font-size: 14px;
  }
}

.cart-wrapper {
  form {
    margin-inline: -8px;
  }

  input {
    padding-block: 12px;
  }
}

.nav-pills.nav {
  --bs-nav-link-color: var(--bs-body-color);
}

.nav-pills .nav-link {
  background: rgba(var(--bs-white-rgb), 1);
  color: var(--bs-body-color);
}

.nav-pills .nav-link:not(:last-child) {
  margin-right: 6px;
}

.nav-pills .nav-link.active {
  background: black;
  color: white;
}

.nav-pills .nav-link.active:first-child(1) {
  background: var(--sushi-yellow);
}

.nav-pills .nav-link.active:nth-child(2) {
  background: black;
  color: rgba(var(--bs-white-rgb), 1);
}

.tab-content .tab-pane .tab-content {
  border-top: 1px solid var(--bs-border-color);
}

.tab-content .tab-pane .nav-pills .nav-link {
  color: var(--bs-body-color) !important;
  background: rgba(var(--bs-white-rgb), 1) !important;
}

.tab-content .tab-pane .nav-pills .nav-link.active {
  background: black !important;
  color: white!important;
}

.mat-mdc-autocomplete-panel {
  width: auto !important;
  /* Override default behavior */
  min-width: 316px;
  /* Ensure a minimum width */
}

.qr-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.form-field-error {
  color: red;
  padding: 5px;
  font-size: 10px;
  font-weight: normal;
}

.form-field-sucess {
  color: green;
  padding: 5px;
  font-size: 10px;
  font-weight: normal;
}
*:focus {
  outline: none;
}

.amount{
  font-family: var(--futura-bold) !important;
}

/* width */
// ::-webkit-scrollbar {
//   width: 10px;
// }

/* Track */
// ::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px grey; 
  // border-radius: 10px;
// }
 
/* Handle */
// ::-webkit-scrollbar-thumb {
  // background:#2b2926;
  // border-radius: 10px;
// }

/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
  // background: #ffd000;  
// }

@media screen and (max-width: 575px) {
.item-search-autocomplete{
  left: 13px;
  right:13px!important;
 }
}

@media screen and (max-width: 575px) {
  .shop-search-autocomplete{
    left: 13px;
    right:13px!important;
   }
  }
